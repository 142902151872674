
$primary: #84D5DC;
$primary-light: #B7FFFF;
$primary-dark: #51A3AA;

$secondary: #F82551;
$secondary-light: #FF667D;
$secondary-dark: #BE0029;

$background: #FFFFFF;
$surface: #FFFFFF;

$error: #B00020;

$on-surface: #000000;

//Emphasis
$emphasis-high: 0.84;
$emphasis-medium: 0.74;
$emphasis-low: 0.38;

