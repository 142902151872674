@import 'src/styles/global';

.Container {
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.04);
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .Overhead {
    @include TextOverline;
    text-align: center;
    color: $primary-dark;
  }
  .Header {
    color: $on-surface;
    opacity: $emphasis-high;
    margin: $spacing-4 0 $spacing-16;
    text-align: center;
  }
  .Paragraph {
    @include TextBody1;
    color: $on-surface;
    opacity: $emphasis-medium;
    text-align: center;
    max-width: 460px;
    margin: 0 0 $spacing-16;
  }
}

@media only screen and (max-width: $sm) {
  .Container {
    padding: $spacing-24 $spacing-48;
  }
}
