@import 'src/styles/global';

.Container {
  display: flex;
  justify-content: center;
  background-color: $surface;
  .Content {
    box-sizing: content-box;
    display: grid;
    grid-template-columns: repeat(3,33fr);
    grid-row-gap: $spacing-48;
    width: 100%;
    max-width: 1280px;
    margin: $spacing-48 80px 117px;

    .SectionHeader {
      grid-column: 1/2;
      box-sizing: content-box;
      width: auto;
      margin: 0 $spacing-24 0 0;
      .Overhead {
        @include TextOverline;
        opacity: $emphasis-medium;
      }
      .Header {
        opacity: $emphasis-high;
      }
    }
    .SectionContent {
      width: 100%;
      grid-column: 2/4;
      display: flex;
      height: auto;
      .ContentBlock {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        &:not(:last-of-type) {
          margin: 0 $spacing-24 0 0;
        }
        & > svg {
          width: 48px;
          height: 48px;
        }
        .Header {
          margin: $spacing-16 0 0;
          opacity: $emphasis-high;
        }
        .Paragraph {
          flex: 1;
          margin: $spacing-16 0;
          @include TextBody1;
          opacity: $emphasis-medium;
        }
        .Button {
          margin: auto 0 0;
        }
      }
      .SubscriptionInfo {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        margin: 0 $spacing-24 0 0;
        .Header {
          margin: $spacing-16 0 0;
          opacity: $emphasis-high;
        }
        .Paragraph {
          flex: 1;
          margin: $spacing-24 0;
          @include TextBody1;
          opacity: $emphasis-medium;
        }
      }
      .SubscriptionDetail {
        flex: 1;
      }
    }
    .SectionHeader {
      grid-column: 1/2;
      .Header {
        white-space: pre-line;
        opacity: $emphasis-high;

        .HeaderColored {
          color: $primary;

          &::after {
            content: " ";
            white-space: pre;
          }
        }
      }
      .SubHeader {
        @include TextSubtitle1;
        opacity: $emphasis-high;
        margin: $spacing-24 0 0;
      }
    }
  }
}

@media only screen and (max-width: $elg) {
  .Container {
    .Content {
      .TopSection {
        flex-direction: column;
        align-items: flex-start;
        .SectionHeader {
          width: 100%;
          margin: 0 $spacing-24 $spacing-24 0;
          .Header {
            white-space: unset;
            opacity: $emphasis-high;

            .HeaderColored {
              color: $primary;

              &::after {
                content: " ";
                white-space: pre;
              }
            }
          }
          .SubHeader {
            max-width: 600px;
            @include TextSubtitle1;
            opacity: $emphasis-high;
            margin: $spacing-24 0 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $elg) {
  .Container {
    .Content {
      margin: $spacing-48 $spacing-48 117px;
      grid-template-columns: 1fr;
      grid-row-gap: $spacing-24;
      .SectionHeader{
        margin: 0 0 $spacing-24;
        &:not(:first-of-type) {
          margin: $spacing-24 0;
        }
      }
      .SectionContent {
        grid-column: 1/3;
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .Container {
    .Content {
      margin: $spacing-48 $spacing-24 117px;
      grid-template-columns: 1fr;
      grid-row-gap: $spacing-24;
      .SectionHeader{
        margin: 0 0 $spacing-8;
        &:not(:first-of-type) {
          margin: $spacing-16 0 $spacing-4;
        }
      }
      .SectionContent {
        flex-direction: column;
        .ContentBlock {
          margin: $spacing-48 0 $spacing-24;
        }
        .SubscriptionInfo {
          margin: 0 0 $spacing-24;
        }
      }
    }
  }
}
