@import './variables';

//Elevation
@mixin elevation02 {
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
}

@mixin elevation08 {
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
}

@mixin elevation16 {
  filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
}

@mixin elevation24 {
  filter: drop-shadow(0px 24px 32px rgba(0, 0, 0, 0.04)) drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
}

$heading-font: 'Syne', sans-serif;

//Text
@mixin TextHeadline1 {
  font-weight: bold;
  font-family: $heading-font;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: 0.15px;
}
@mixin TextHeadline2 {
  font-weight: bold;
  font-family: $heading-font;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.15px;
}
@mixin TextHeadline3 {
  font-weight: bold;
  font-family: $heading-font;
  font-size: 33px;
  line-height: 120%;
  letter-spacing: 0.2px;
}
@mixin TextHeadline4 {
  font-weight: bold;
  font-family: $heading-font;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: 0.2px;
}
@mixin TextHeadline5 {
  font-weight: bold;
  font-family: $heading-font;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.3px;
}
@mixin TextHeadline6 {
  font-weight: bold;
  font-family: $heading-font;
  font-size: 19px;
  line-height: 120%;
  letter-spacing: 0.3px;
}

@mixin TextSubtitle1 {
  font-weight: normal;
  font-family: $heading-font;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.15px;
}
@mixin TextSubtitle2 {
  font-weight: normal;
  font-family: $heading-font;
  font-size: 13px;
  line-height: 148%;
  letter-spacing: 0.17px;
}

@mixin TextBody1 {
  font-weight: normal;
  font-size: 16px;
  line-height: 148%;
  letter-spacing: 0.15px;
}
@mixin TextBody2 {
  font-weight: normal;
  font-size: 13px;
  line-height: 148%;
  letter-spacing: 0.17px;
}

@mixin TextButton {
  font-weight: normal;
  font-size: 13px;
  line-height: 148%;
  letter-spacing: 0.17px;
}

@mixin TextCaption {
  font-weight: normal;
  font-size: 11px;
  line-height: 148%;
  letter-spacing: 0.25px;
}

@mixin TextOverline {
  font-weight: normal;
  font-size: 11px;
  line-height: 128%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
