@import 'src/styles/global';

.Container {
  margin-top: 24px;
  border: $border;
  border-radius: 8px;
  .File {
    &:hover, &:hover * {
      text-decoration: none !important;
    }

    height: 56px;
    padding: 0 $spacing-16;
    display: grid;
    grid-template-columns: min-content auto 24px;
    align-items: center;
    &:not(:last-of-type) {
      border-bottom: $border;
    }

    .Icon {
      color: $secondary-light;
      display: flex;
      align-items: center;
      & > svg {
        width: 12px;
        height: auto;
      }
      & > p {
        @include TextCaption;
        margin: 0 0 0 $spacing-4;
      }
    }
    .Name {
      color: $on-surface;
      text-decoration: none;
      display: block;
      margin: 0 0 0 $spacing-8;
      @include TextSubtitle1;
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & > svg {
      width: 16px;
      height: auto;
    }
  }
}
