@import 'src/styles/global';

$column-minmax: minmax(300px, 1fr);

@mixin paginateShared {
  @include TextBody2;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 $spacing-4;
  width: 32px;
  height: 32px;
  border: $border;
  border-radius: 4px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.PaginateContainer {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;

  .PaginatePage {
    @include paginateShared;
  }

  .PaginatePrevious {
    @include paginateShared;
    margin-left: 0;

    & > a {
      width: 100%;
      height: 100%;
      outline: none;

      &:after {
        pointer-events: none;
        background: none;
        content: url('../../assets/icons/icon_prev.svg');
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 2px 0 0;
      }
    }
  }

  .PaginateNext {
    @include paginateShared;
    margin-right: 0;

    & > a {
      width: 100%;
      height: 100%;
      outline: none;

      &:after {
        pointer-events: none;
        background: none;
        content: url('../../assets/icons/icon_next.svg');
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 2px 0 0;
      }
    }
  }

  .PaginateDisabled {
    opacity: $emphasis-low;
  }

  .PaginateActive {
    & > a {
      background: rgba(132, 213, 220, 0.1);
      border-color: $primary-dark;
    }
  }

  .PaginateBreak {
    @include paginateShared;
    border: none;

    @media only screen and (max-width: $md) {
      width: auto;
    }
  }
}
