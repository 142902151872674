@import 'src/styles/global';

@mixin horizontal-layout {
  @media (min-width: 940px) {
    @content;
  }
}

@mixin vertical-layout {
  @media (max-width: 939px) {
    @content;
  }
}

.FormLayout {
  grid-area: form;
  grid-row-gap: $spacing-32;
  display: grid;
  max-width: $page-content-max-width;
  
  @include horizontal-layout() {
    grid-template-columns: 1fr 1fr;
    padding: 0 0 36px 0;
    grid-column-gap: $spacing-24;
  }

  @include vertical-layout() {
    padding: 0 0 36px 0;
  }
}

.SlideFromLeft {
  animation: slide-from-left .4s;
}

.SlideFromRight {
  animation: slide-from-right .4s;
}

@keyframes slide-from-left {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

.Left {
  @include horizontal-layout() {
    grid-column: 1;
  }
  @include vertical-layout() {
  }
}

.Right {
  @include horizontal-layout() {
    grid-column: 2;
  }
  @include vertical-layout() {
    // padding: 0 $spacing-24;
  }
}

.Full {
  @include horizontal-layout() {
    grid-column: 1 / span 2;
  }
  @include vertical-layout() {
    // padding: 0 $spacing-24;
  }
}

.Separator {
  border-top: $border;
  margin: 0 0 24px 0;
}

.Heading {
  opacity: $emphasis-high;
}

.Note {
  opacity: $emphasis-high;
  margin: $spacing-8 0 0 0;
}
