@import 'src/styles/global';

.Container {
  display: flex;
  align-items: center;
  text-align: center;

  & > svg {
    margin: 0 $spacing-4 0 0;
  }

  .Text {
    color: $on-surface;
    opacity: $emphasis-high;
    white-space: nowrap;
    overflow: hidden;
  }
}

.small {
  & > svg {
    width: 13px;
    height: 13px;
  }

  .Text {
    @include TextSubtitle2;
  }
}

.big {
  & > svg {
    width: 16px;
    height: 16px;
  }

  .Text {
    @include TextSubtitle1;
  }
}
