
// Mobile widths
$usm: 375px;
$esm: 543px;
$sm: 767px;
$md: 991px;
$lg: 1199px;
$elg: 1200px;
$slg: 1400px;

//Spacing
$spacing-64: 64px;
$spacing-48: 48px;
$spacing-32: 32px;
$spacing-24: 24px;
$spacing-16: 16px;
$spacing-8: 8px;
$spacing-4: 4px;

//Sizing
$navbar-height: 84px;
$footer-height: 88px;
$widget-space-width: 325px;

//Other
$border: 1px solid rgba(0, 0, 0, 0.12);

$page-content-max-width: 1280px;

$session-box-big-single-width: 410px;
$session-box-big-single-height: 440px;
