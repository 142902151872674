@import 'src/styles/global';

.Container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 6px;
  background: none;
  border: $border;
  border-radius: 100px;
  &:hover,
  &:focus {
    background: none;
  }

  &:focus {
    outline: none;
  }
  & > span {
    margin: 0 6px;
    @include TextButton;
    color: $on-surface;
    opacity: $emphasis-high;
  }
}
